import { useCallback, useEffect, useState } from 'react';

interface GeolocationResult {
  location: null | Pick<
    GeolocationCoordinates,
    'longitude' | 'latitude' | 'accuracy'
  >;
  error: null | GeolocationPositionError;
  refetch: () => void;
  startWatching: () => void;
  stopWatching: () => void;
}

interface GeoLocationProps extends PositionOptions {
  skip?: boolean;
}

export const useGeolocation = ({
  skip = true,
  ...positionOptions
}: GeoLocationProps): GeolocationResult => {
  const [isWatching, setIsWatching] = useState(false);
  const [location, setLocation] = useState<null | Pick<
    GeolocationCoordinates,
    'longitude' | 'latitude' | 'accuracy'
  >>(null);
  const [error, setError] = useState<null | GeolocationPositionError>(null);

  const onGetLocationSuccess = (geolocationPosition: GeolocationPosition) => {
    const { latitude, longitude, accuracy } = geolocationPosition.coords;
    setError(null);
    setLocation((location) =>
      latitude !== location?.latitude ||
      longitude !== location?.longitude ||
      accuracy !== location?.accuracy
        ? { latitude, longitude, accuracy }
        : location
    );
  };

  const onGetLocationError = (err: GeolocationPositionError) => {
    // https://w3c.github.io/geolocation-api/#position_error_interface
    setError(err);
  };

  const fetchLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        onGetLocationSuccess,
        onGetLocationError,
        positionOptions
      );
    }
  }, [positionOptions]);

  const watchLocation = useCallback(() => {
    if (navigator.geolocation) {
      return navigator.geolocation.watchPosition(
        onGetLocationSuccess,
        onGetLocationError,
        positionOptions
      );
    }
    return undefined;
  }, [positionOptions]);

  useEffect(() => {
    if (skip) return;
    fetchLocation();
  }, [fetchLocation, skip]);

  useEffect(() => {
    if (!isWatching) return undefined;
    const id = watchLocation();
    return () => {
      if (id) navigator.geolocation.clearWatch(id);
    };
  }, [isWatching, watchLocation]);

  const refetch = () => {
    fetchLocation();
  };

  const startWatching = () => {
    setIsWatching(true);
  };

  const stopWatching = () => {
    setIsWatching(false);
  };

  return { location, error, refetch, startWatching, stopWatching };
};
