import { green, grey, red, yellow } from '@material-ui/core/colors';

import { createMuiTheme } from '@material-ui/core/styles';
import createSpacing from '@material-ui/core/styles/createSpacing';

const theme = createMuiTheme({
  overrides: {
    MuiAvatar: {
      root: {
        fontSize: '1rem'
      }
    },
    MuiCssBaseline: {
      '@global': {
        html: {
          fontSize: 16
        },
        body: {
          'overscroll-behavior-y': 'none',
          fontSize: '1rem'
        }
      }
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        '&:hover, &:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiListItemIcon: {
      root: { minWidth: 48 }
    },
    MuiTooltip: {
      tooltip: {
        padding: createSpacing(6)(1, 1.5),
        fontSize: `${12 / 16}rem`
      }
    }
  },
  props: {
    MuiButton: {
      disableElevation: true
    },
    MuiContainer: {
      maxWidth: 'md'
    },
    MuiLink: {
      underline: 'none'
    },
    MuiMenu: {
      elevation: 2
    },
    MuiSvgIcon: {
      fontSize: 'small'
    },
    MuiTooltip: {
      enterDelay: 700
    }
  },
  palette: {
    text: {
      secondary: grey['500']
    },
    success: {
      light: green['300'],
      main: green['700'],
      dark: green['800'],
      contrastText: '#FFFFFF'
    },
    info: {
      light: yellow['300'],
      main: yellow['700'],
      dark: yellow['900'],
      contrastText: '#FFFFFF'
    },
    error: {
      light: red['300'],
      main: red['700'],
      dark: red['900'],
      contrastText: '#FFFFFF'
    }
  },
  shape: {
    borderRadius: 2
  },
  typography: {
    htmlFontSize: 16,
    fontSize: 14,
    h1: {
      fontSize: `${26 / 16}rem`,
      fontWeight: 400,
      lineHeight: 1.2
    },
    h2: {
      fontSize: `${22 / 16}rem`,
      fontWeight: 400,
      lineHeight: 1.2
    },
    h3: {
      fontSize: `${20 / 16}rem`,
      fontWeight: 400,
      lineHeight: 1.2
    },
    h4: {
      fontSize: `${18 / 16}rem`,
      fontWeight: 400,
      lineHeight: 1.33
    },
    h5: {
      fontSize: `${15 / 16}rem`,
      fontWeight: 400,
      lineHeight: 1.6
    },
    h6: {
      fontSize: `${15 / 16}rem`,
      fontWeight: 400,
      lineHeight: 1.6
    },
    subtitle1: {
      fontSize: `${14 / 16}rem`,
      fontWeight: 400,
      lineHeight: 1.428571429
    },
    subtitle2: {
      fontSize: `${13 / 16}rem`,
      fontWeight: 400,
      lineHeight: 1.538461538
    },
    body1: {
      fontSize: `${13 / 16}rem`,
      lineHeight: 1.538461538
    },
    body2: {
      fontSize: `${13 / 16}rem`,
      lineHeight: 1.538461538
    },
    button: {
      fontSize: `${13 / 16}rem`,
      lineHeight: 1.538461538
    },
    caption: {
      fontSize: `${11 / 16}rem`,
      lineHeight: 1.818181818
    }
  }
});

export default theme;
