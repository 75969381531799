import { Button, ButtonProps } from '@material-ui/core';
import * as React from 'react';

type MapButtonProps = ButtonProps;

/**
 * Small, square button intended for overlay on a map.
 * `children` would typically be an icon but may be any ReactNode.
 */
const MapButton: React.FC<MapButtonProps> = ({
  children,
  style,
  ...buttonProps
}) => (
  <Button
    {...buttonProps}
    style={{
      minHeight: 30,
      minWidth: 30,
      padding: 5,
      borderRadius: 0,
      ...style
    }}
  >
    {children}
  </Button>
);

export default MapButton;
