import { useEffect, useState } from 'react';

import { useMapViewport } from './useMapViewport';

interface MapZoomIntegerResult {
  /**
   * Integer value (floor) only.
   * @example
   * zoom 12 = level 12
   * zoom 12.1 = level 12
   */
  level?: number;
  /**
   * True if current map zoom is between integer values.
   * @example
   * zoom 12 = betweenLevels false
   * zoom 12.1 = betweenLevels true
   */
  betweenLevels?: boolean;
  /**
   * Numeric value useful for comparisons.
   * Zooms that are between levels are always a .5 value.
   * `levelCompare` allows easy logic like `levelCompare` > 12
   * without concern for the specific decimal value.
   * @example
   * zoom 12 = levelCompare 12
   * zoom 12.1 = levelCompare 12.5
   * zoom 12.6 = levelCompare 12.5
   */
  levelCompare?: number;
}

/**
 * Map zooms may be fractional values (ie. 12.12345), and the value frequently changes.
 * In cases where only the floor integer value is needed,
 * frequent zoom changes will cause unneccesary rerenders.
 * `useMapZoomInteger` provides values only describing the whole integers
 * with efficient change detection.
 */
export const useMapZoomInteger = (): MapZoomIntegerResult => {
  const { zoom } = useMapViewport();
  const [resultState, setResultState] = useState<MapZoomIntegerResult>({});

  useEffect(() => {
    if (zoom === undefined) {
      return;
    }

    const level = Math.floor(zoom);
    const betweenLevels = zoom % 1 > 0;
    const levelCompare = betweenLevels ? level + 0.5 : level;

    setResultState((prev) =>
      // Returning `prev` when unchanged will prevent rerenders
      prev.levelCompare === levelCompare
        ? prev
        : { level, betweenLevels, levelCompare }
    );
  }, [zoom]);

  return resultState;
};
