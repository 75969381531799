import {
  Avatar,
  AvatarProps,
  Box,
  BoxProps,
  Divider,
  Typography
} from '@material-ui/core';
import React from 'react';

import {
  AnyAttribute,
  ImageAttribute,
  LinkAttribute,
  TextAttribute
} from './interfaces';
import ImageAttributeItem from './ImageAttributeItem';
import LinkAttributeItem from './LinkAttributeItem';
import TextAttributeItem from './TextAttributeItem';

type RootBoxProps = BoxProps;

export interface FeatureDetailsProps extends RootBoxProps {
  /** Optional avatar image. Provide a `src` and/or `srcSet`. */
  avatar?: Pick<AvatarProps, 'src' | 'srcSet'>;
  title: string;
  subtitle?: string;
  attributes: AnyAttribute[];
}

const FeatureDetails: React.FC<FeatureDetailsProps> = ({
  avatar,
  title,
  subtitle,
  attributes,
  p = 2,
  ...rootBoxProps
}) => (
  <Box
    p={p}
    {...rootBoxProps}
    display="flex"
    flexDirection="column"
    overflow="auto"
  >
    <Box flex="0 0 auto" display="flex" flexDirection="row" alignItems="center">
      {avatar && (
        <Box mr={2}>
          <Avatar {...avatar} />
        </Box>
      )}
      <Box>
        <Typography
          variant="h5"
          style={{ textTransform: 'uppercase' }}
          gutterBottom={!!subtitle}
        >
          {title}
        </Typography>
        {subtitle && (
          <Typography variant="subtitle1" color="textSecondary">
            {subtitle}
          </Typography>
        )}
      </Box>
    </Box>
    {attributes.length > 0 && (
      <React.Fragment>
        <Box flex="0 0 auto" pt={2}>
          <Divider />
        </Box>
        <Box flex={1} pt={2}>
          {attributes.map((attribute, index) => {
            return {
              // NOTE: checking for null as a string value is for when identify passes
              // back attributes derived from lja metadata
              image:
                (attribute as ImageAttribute).url &&
                (attribute as ImageAttribute).url !== 'null' ? (
                  <ImageAttributeItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    label={attribute.label}
                    url={(attribute as ImageAttribute).url}
                  />
                ) : null,
              text:
                ((attribute as TextAttribute).value ||
                  (attribute as TextAttribute).value === '0') &&
                (attribute as TextAttribute).value !== 'null' ? (
                  <TextAttributeItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    label={attribute.label}
                    value={(attribute as TextAttribute).value}
                  />
                ) : null,
              link:
                (attribute as LinkAttribute).url &&
                (attribute as LinkAttribute).url !== 'null' ? (
                  <LinkAttributeItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    label={attribute.label}
                    url={(attribute as LinkAttribute).url}
                  />
                ) : null
            }[attribute.kind];
          })}
        </Box>
      </React.Fragment>
    )}
  </Box>
);

export default FeatureDetails;
