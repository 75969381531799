import { Theme, fade, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  '@global': {
    '.mapboxgl-map': {
      ...theme.typography.body1,
      '& canvas:focus': {
        outline: 0
      }
    },

    '.mapboxgl-ctrl.mapboxgl-ctrl-attrib': {
      backgroundColor: fade(theme.palette.background.paper, 0.6)
    },

    '.mapboxgl-ctrl-attrib': {
      ...theme.typography.caption,
      lineHeight: 2,
      '&, & a': {
        color: theme.palette.text.primary
      }
    },

    '.mapboxgl-ctrl-attrib.mapboxgl-compact': {
      minHeight: 24,
      backgroundColor: fade(theme.palette.background.paper, 0.6),
      borderRadius: 24,
      '&.mapboxgl-compact-show': {
        borderRadius: theme.shape.borderRadius
      }
    },

    '.mapboxgl-ctrl-attrib-button': {
      // Material info outlined
      backgroundImage:
        "url(\"data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='black' width='20px' height='20px'%3E%3Cpath d='M0 0h24v24H0V0z' fill='none'/%3E%3Cpath d='M11 7h2v2h-2zm0 4h2v6h-2zm1-9C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z'/%3E%3C/svg%3E\")",
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundColor: fade(theme.palette.background.paper, 0.5)
    }
  }
}));

export default useStyles;
