const ddToDms = (
  coordinate: number,
  posSymbol: 'N' | 'E',
  negSymbol: 'S' | 'W'
) => {
  const pad = (num: number) => {
    return num < 10 ? `0${num.toString()}` : num.toString();
  };

  const dd = Math.abs(coordinate);
  const d = Math.floor(dd);
  const m = Math.floor((dd - d) * 60);
  const s = Math.round((dd - d - m / 60) * 3600 * 100) / 100;
  const directionSymbol = dd === coordinate ? posSymbol : negSymbol;
  return `${pad(d)}° ${pad(m)}' ${pad(s)}" ${directionSymbol}`;
};

export const decimalDegrees = (point: { lng: number; lat: number }): string =>
  `${point.lat.toFixed(4)}, ${point.lng.toFixed(4)}`;

export const degMinSec = (point: { lng: number; lat: number }): string =>
  `${ddToDms(point.lat, 'N', 'S')}, ${ddToDms(point.lng, 'E', 'W')}`;

export const formatFeet = (lengthMiles: number): string => {
  const feet = lengthMiles * 5280;
  const formatted = feet.toLocaleString(undefined, {
    maximumFractionDigits: 0
  });
  return `${formatted} Feet`;
};

export const miles = (lengthMiles: number): string => {
  const formatted = lengthMiles.toLocaleString(undefined, {
    maximumFractionDigits: 2
  });
  return `${formatted} Miles`;
};

export const sqFeet = (areaSqMeter: number): string => {
  const sqFeet = areaSqMeter * 10.7639;
  const formatted = sqFeet.toLocaleString(undefined, {
    maximumFractionDigits: 2
  });
  return `${formatted} Sq Feet`;
};

export const acres = (areaSqMeter: number): string => {
  const acres = areaSqMeter * 0.000247105;
  const formatted = acres.toLocaleString(undefined, {
    maximumFractionDigits: 2
  });
  return `${formatted} Acres`;
};
