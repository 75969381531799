import { Paper, PaperProps } from '@material-ui/core';
import React from 'react';

type MapOverlayProps = PaperProps;

/** Component intended to be placed over a map */
const MapOverlay: React.FC<MapOverlayProps> = ({ children, ...props }) => (
  <Paper elevation={2} {...props}>
    {children}
  </Paper>
);

export default MapOverlay;
