import { Box, BoxProps, Link } from '@material-ui/core';
import React from 'react';

import { LinkAttribute } from './interfaces';

type RootBoxProps = BoxProps;

export type LinkAttributeItemProps = RootBoxProps & Omit<LinkAttribute, 'kind'>;

const LinkAttributeItem: React.FC<LinkAttributeItemProps> = ({
  label,
  url,
  ...rootBoxProps
}) => (
  <Box mt={1} {...rootBoxProps}>
    <Link
      variant="body1"
      display="block"
      href={url}
      target="blank"
      rel="noopener"
    >
      {label}
    </Link>
  </Box>
);

export default LinkAttributeItem;
