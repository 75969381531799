export const FEATURESTATE_HIGHLIGHT = 'lja:highlight';

/** Layer metadata. Properties to support layer identify (query on map click). */
export const METADATA_IDENTIFY = 'lja:identify';

export const METADATA_LEGEND = 'lja:legend';
export const METADATA_LEGENDS = 'lja:legends';

export const METADATA_VISIBILITIES = 'lja:visibilities';
export const METADATA_VISIBILITY = 'lja:visibility';

/** Root level metadata. URL to download style. */
export const METADATA_URL = 'lja:url';
