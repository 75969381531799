import React from 'react';
import {
  Box,
  BoxProps,
  createStyles,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import NoLayersIcon from '@material-ui/icons/LayersClearOutlined';
import { LegendKind, useLegend } from './useLegend';
import LoadingLegendItem from './LoadingLegendItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    smallImageText: {
      paddingLeft: theme.spacing(2)
    },
    image: {
      borderRadius: theme.shape.borderRadius
    }
  })
);

type RootSizing = Pick<BoxProps, 'flex' | 'height' | 'width'>;

type LegendProps = RootSizing;

const Legend: React.FC<LegendProps> = ({ ...rootProps }) => {
  const classes = useStyles();
  const { legends, loading } = useLegend();

  if (loading) {
    return <LoadingLegendItem />;
  }

  if (legends.length === 0) {
    // Items have loaded but no visibility layers are on
    return (
      <Box
        p={3}
        display="flex"
        flexDirection="row"
        alignItems="center"
        justifyContent="center"
        {...rootProps}
      >
        <Typography
          style={{ lineHeight: 1, marginRight: 16 }}
          component="span"
          color="textSecondary"
        >
          <NoLayersIcon />
        </Typography>
        <Typography component="span" color="textSecondary">
          No Layers Selected
        </Typography>
      </Box>
    );
  }

  return (
    <Box flex={1} minHeight={0} overflow="hidden auto" {...rootProps}>
      <List component="nav">
        {legends.map((legend) => {
          if (legend.kind === LegendKind.SmallImage) {
            return (
              <ListItem key={legend.name} component="div">
                <img
                  className={classes.image}
                  height={50}
                  width={50}
                  alt={legend.name}
                  src={legend.img}
                />
                <ListItemText
                  className={classes.smallImageText}
                  primary={legend.name}
                />
              </ListItem>
            );
          }

          if (legend.kind === LegendKind.LargeImage) {
            return (
              <ListItem key={legend.name} component="div">
                <Box display="flex" flexDirection="column" width="100%">
                  <ListItemText primary={legend.name} />
                  <img
                    className={classes.image}
                    width="100%"
                    alt={legend.name}
                    src={legend.img}
                  />
                </Box>
              </ListItem>
            );
          }

          return null;
        })}
      </List>
    </Box>
  );
};

export default Legend;
