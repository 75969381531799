import { Grid, GridProps, Typography } from '@material-ui/core';
import React from 'react';

import { TextAttribute } from './interfaces';

type RootGridProps = GridProps;

export type TextAttributeItemProps = RootGridProps &
  Omit<TextAttribute, 'kind'>;

const TextAttributeItem: React.FC<TextAttributeItemProps> = ({
  label,
  value,
  ...rootGridProps
}) => (
  // Padding 0 on grid items (bottom on label, top on value)
  // combined with gutterBottom on label
  // for better spacing when container wraps.

  <Grid
    spacing={2}
    justify="space-between"
    alignItems="baseline"
    {...rootGridProps}
    container
  >
    <Grid item xs="auto" style={{ paddingBottom: 0 }}>
      <Typography color="textSecondary" gutterBottom>
        {label}
      </Typography>
    </Grid>
    <Grid item xs="auto" style={{ paddingTop: 0 }}>
      <Typography>{value}</Typography>
    </Grid>
  </Grid>
);

export default TextAttributeItem;
