import { Style } from 'mapbox-gl';
import { useCallback, useEffect, useState } from 'react';

import { useMapContext } from './MapContext';

interface MapStyleResult {
  mapStyle: Style | undefined;
  setMapStyleFromURL: (style: string) => void;
}

export const useMapStyle = (): MapStyleResult => {
  const { map: mapInstance } = useMapContext();

  const [styleState, setStyleState] = useState<Style>();

  useEffect(() => {
    if (!mapInstance) {
      return undefined;
    }

    const onStyleLoad = () => {
      setStyleState(mapInstance.getStyle());
    };

    mapInstance.on('styledata', onStyleLoad);
    onStyleLoad();

    return () => {
      mapInstance.off('styledata', onStyleLoad);
    };
  }, [mapInstance]);

  const setStyleFromURL = useCallback(
    (newMapStyle: string) => {
      if (!mapInstance) {
        return;
      }
      mapInstance.setStyle(newMapStyle);
    },
    [mapInstance]
  );

  return { mapStyle: styleState, setMapStyleFromURL: setStyleFromURL };
};
