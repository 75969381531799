import turfArea from '@turf/area';
import { Position, lineString, polygon } from '@turf/helpers';
import turfLength from '@turf/length';
import { MapMouseEvent } from 'mapbox-gl';
import { useEffect, useState } from 'react';

import { useMapContext } from '../MapContext';
import { useGeoJSONLayer } from '../useGeoJSONLayer';

import {
  buildCurrentMouseFeature,
  buildLineFeature,
  buildPointFeatures,
  buildProjectedLineFeature,
  Coordinate,
  CoordinateArray
} from './buildFeature';
import { mapLayers } from './mapLayers';

interface State {
  clicks: CoordinateArray;
  currentMouse?: Coordinate;
  isFinished: boolean;
  lastPoint: Coordinate | null;
  length: number | null;
  area: number | null;
}

type ReturnedState = Pick<
  State,
  'length' | 'area' | 'lastPoint' | 'isFinished'
>;

interface MeasureResult extends ReturnedState {
  isActive: boolean;
  activate: () => void;
  deactivate: () => void;
  reset: () => void;
}

interface MeasureProps {
  /** Use `activate`/`deactivate` after initial hook configuration */
  isActive: boolean;
}

const initialState = {
  clicks: [],
  currentMouse: undefined,
  isFinished: false,
  lastPoint: null,
  length: null,
  area: null
};

export const useMeasure = ({ isActive }: MeasureProps): MeasureResult => {
  const { map: mapInstance } = useMapContext();
  const { setData } = useGeoJSONLayer({ layers: mapLayers });

  const [activeState, setActiveState] = useState<boolean>(isActive);
  const [state, setState] = useState<State>(initialState);

  const { isFinished, length, area, lastPoint } = state;

  const activate = (): void => {
    setActiveState(true);
  };

  const deactivate = (): void => {
    setActiveState(false);
  };

  const reset = () => {
    setState(initialState);
  };

  /**
   * Watches for mouse click and current mouse position changes to draw features to map
   */
  useEffect(() => {
    const { clicks, currentMouse } = state;

    const features: GeoJSON.Feature<GeoJSON.Geometry>[] = [
      buildPointFeatures(clicks),
      buildLineFeature(clicks)
    ];

    if (currentMouse) {
      features.push(buildCurrentMouseFeature(currentMouse));
    }

    if (currentMouse && clicks.length) {
      features.push(buildProjectedLineFeature(clicks, currentMouse));
    }

    setData({
      type: 'FeatureCollection',
      features
    });
  }, [state, setData]);

  /**
   * Handles event listeners and feature prep calculations
   */
  useEffect(() => {
    if (!activeState) {
      setState(initialState);
      return undefined;
    }

    if (!mapInstance || isFinished) return undefined;

    const onMapClick = (evt: MapMouseEvent) => {
      setState((prevstate) => {
        // Checking to see if same spot was clicked (user is finished with measurement)
        const isFinished = !!(
          prevstate.clicks.length &&
          JSON.stringify(prevstate.clicks[prevstate.clicks.length - 1]) ===
            JSON.stringify(evt.lngLat)
        );

        let clicks = [];
        let lastPoint = null;
        let length = null;
        let area = null;

        // Making sure not to add duplicate click location when measurement is finished
        clicks = isFinished
          ? prevstate.clicks
          : [...prevstate.clicks, evt.lngLat];

        // Calc length
        if (clicks.length > 1) {
          const points =
            clicks.length > 2 && isFinished ? [...clicks, clicks[0]] : clicks;

          const positionArray: Position[] = points.map((click) => {
            return [click.lng, click.lat];
          });
          const line = lineString(positionArray);
          length = turfLength(line, { units: 'miles' });
        }

        // Calc area
        if (clicks.length > 2) {
          const points = [...clicks, clicks[0]];
          const positionArrayArray: Position[][] = [
            points.map((click) => {
              return [click.lng, click.lat];
            })
          ];

          const poly = polygon(positionArrayArray);
          area = turfArea(poly);
        }

        // LastPoint used for start point of projected line
        if (clicks.length) {
          lastPoint = clicks[clicks.length - 1];
        }

        // When finished, add first point to draw polygon with closed boundary path
        if (isFinished && clicks.length > 2) {
          clicks = [...clicks, clicks[0]];
        }

        return {
          clicks,
          isFinished,
          lastPointClicked: evt.lngLat,
          currentMouse: isFinished ? undefined : prevstate.currentMouse,
          lastPoint,
          length,
          area
        };
      });
    };

    const onMapMouseMove = ({ lngLat }: MapMouseEvent) => {
      setState((prevstate) => ({
        ...prevstate,
        currentMouse: lngLat
      }));
    };

    const onMapMouseOut = () => {
      setState((prevstate) => ({
        ...prevstate,
        currentMouse: undefined
      }));
    };

    mapInstance.on('click', onMapClick);
    mapInstance.on('mouseout', onMapMouseOut);
    mapInstance.on('mousemove', onMapMouseMove);
    mapInstance.doubleClickZoom.disable();
    mapInstance.getCanvas().style.cursor = 'crosshair';

    return () => {
      mapInstance.off('click', onMapClick);
      mapInstance.off('mouseout', onMapMouseOut);
      mapInstance.off('mousemove', onMapMouseMove);
      mapInstance.getCanvas().style.cursor = '';
    };
  }, [mapInstance, activeState, isFinished]);

  return {
    isActive: activeState,
    length,
    area,
    lastPoint,
    isFinished,
    activate,
    deactivate,
    reset
  };
};
