import { CircleLayer, FillLayer, LineLayer } from 'mapbox-gl';

export const mapLayers = [
  {
    type: 'fill',
    paint: {
      'fill-color': 'rgba(114, 141, 113, 1)',
      'fill-opacity': 0.4
    },
    layout: { visibility: 'visible' },
    minzoom: 0,
    maxzoom: 24
  } as FillLayer,
  {
    type: 'line',
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
      visibility: 'visible'
    },
    paint: {
      'line-width': 2.5,
      'line-color': 'rgba(230, 255, 230, 1)',
      'line-opacity': 0.8
    },
    minzoom: 0,
    maxzoom: 24
  } as LineLayer,
  {
    type: 'circle',
    paint: {
      'circle-radius': 5,
      'circle-color': 'rgba(120, 164, 116, 1)'
    },
    layout: { visibility: 'visible' },
    minzoom: 0,
    maxzoom: 24
  } as CircleLayer,
  {
    type: 'line',
    filter: ['==', ['get', 'projected'], true],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
      visibility: 'visible'
    },
    paint: {
      'line-width': 2.5,
      'line-color': 'rgba(230, 255, 230, 1)',
      'line-opacity': 0.8,
      'line-dasharray': [1, 3] as number[]
    },
    minzoom: 0,
    maxzoom: 24
  } as LineLayer
];
