import { SvgIconProps } from '@material-ui/core';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import * as React from 'react';

type GeolocationPulseProps = Pick<SvgIconProps, 'color'>;

const GeolocationPulse: React.FC<GeolocationPulseProps> = ({
  color = 'primary'
}) => {
  const [pulse, setPulse] = React.useState(true);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setPulse(!pulse);
    }, 800);
    return () => {
      clearInterval(interval);
    };
  });

  if (pulse) {
    return <MyLocationIcon color={color} style={{ fontSize: 16 }} />;
  }
  return <LocationSearchingIcon color={color} style={{ fontSize: 16 }} />;
};

export default GeolocationPulse;
