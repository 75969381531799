import * as React from 'react';
import { AppBar, AppBarProps, Toolbar } from '@material-ui/core';

interface HeaderProps extends Pick<AppBarProps, 'color'> {
  minHeight?: number;
}

const Header: React.FC<HeaderProps> = ({
  color = 'primary',
  minHeight,
  children
}) => (
  <AppBar position="static" elevation={0} color={color}>
    <Toolbar style={{ minHeight }}>{children}</Toolbar>
  </AppBar>
);

export default Header;
