import { Box, BoxProps, Tab, Tabs } from '@material-ui/core';
import React from 'react';

import LayerVisibility from '../LayerVisibility';
import Legend from '../Legend';

type RootSizing = Pick<BoxProps, 'flex' | 'height' | 'width'>;

type LayersProps = RootSizing;

const Layers: React.FC<LayersProps> = ({ ...rootProps }) => {
  const [tabValue, setTabValue] = React.useState(0);

  const handleTabChange = (
    _event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setTabValue(newValue);
  };

  const renderTabContent = (val: number) => {
    if (val === 0) {
      return <LayerVisibility />;
    }
    return <Legend />;
  };

  return (
    <Box display="flex" flexDirection="column" minHeight={0} {...rootProps}>
      <Tabs value={tabValue} variant="fullWidth" onChange={handleTabChange}>
        <Tab label="Visibility" />
        <Tab label="Legend" />
      </Tabs>
      <Box flex={1} minHeight={0} display="flex" flexDirection="column">
        {renderTabContent(tabValue)}
      </Box>
    </Box>
  );
};

export default Layers;
