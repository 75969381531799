import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import HideIcon from '@material-ui/icons/VisibilityOffOutlined';
import React from 'react';

interface HideAllListItemProps {
  onClick: () => void;
}

const HideAllListItem: React.FC<HideAllListItemProps> = ({ onClick }) => (
  <ListItem button onClick={onClick} dense>
    <ListItemIcon>
      <HideIcon color="primary" />
    </ListItemIcon>
    <ListItemText primary="Hide all layers" />
  </ListItem>
);

export default HideAllListItem;
