import { FitBoundsOptions, LngLatLike, LngLatBoundsLike } from 'mapbox-gl';
import { useCallback } from 'react';

import { useMapContext } from './MapContext';

interface MapNavigationResult {
  zoomIn: () => void;
  zoomOut: () => void;
  zoomTo: (zoom: number) => void;
  flyTo: (center: LngLatLike, zoom: number) => void;
  fitBounds: (
    bounds: LngLatBoundsLike,
    options?: Pick<FitBoundsOptions, 'padding' | 'maxZoom'>
  ) => void;
}

export const useMapNavigation = (): MapNavigationResult => {
  const { map: mapInstance } = useMapContext();

  const zoomIn = useCallback(() => {
    if (!mapInstance) {
      return;
    }
    mapInstance.zoomIn();
  }, [mapInstance]);

  const zoomOut = useCallback(() => {
    if (!mapInstance) {
      return;
    }
    mapInstance.zoomOut();
  }, [mapInstance]);

  const zoomTo = useCallback(
    (zoom: number) => {
      if (!mapInstance) {
        return;
      }
      mapInstance.zoomTo(zoom);
    },
    [mapInstance]
  );

  const flyTo = useCallback(
    (center: LngLatLike, zoom: number) => {
      if (!mapInstance) {
        return;
      }
      mapInstance.flyTo({ center, zoom });
    },
    [mapInstance]
  );

  const fitBounds = useCallback(
    (bounds: LngLatBoundsLike, options = {}) => {
      if (!mapInstance) {
        return;
      }
      mapInstance.fitBounds(bounds, options);
    },
    [mapInstance]
  );

  return {
    zoomIn,
    zoomOut,
    zoomTo,
    flyTo,
    fitBounds
  };
};
