import React, { useContext } from 'react';

interface MapboxContextProps {
  accessToken: string;
}

const MapboxContext = React.createContext<MapboxContextProps | undefined>(
  undefined
);

export const useMapboxContext = (): MapboxContextProps => {
  const context = useContext(MapboxContext);
  if (context === undefined) {
    throw new Error('useMapboxContext should be used in a MapboxProvider');
  }
  return context as MapboxContextProps;
};

export const MapboxProvider: React.FC<MapboxContextProps> = ({
  accessToken,
  children
}) => {
  return (
    <MapboxContext.Provider value={{ accessToken }}>
      {children}
    </MapboxContext.Provider>
  );
};
