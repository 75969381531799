import { FEATURESTATE_HIGHLIGHT } from '../constants';

import { useMapContext } from './MapContext';

export interface HighlightableFeature {
  source: string;
  sourceLayer?: string;
  id: string | number;
}

type unhighlightFunc = () => void;

interface MapFeatureStateResult {
  /**
   * Highlight map feature
   * @returns Function to unhighlight highlighted feature
   */
  highlightFeature: (feature: HighlightableFeature) => unhighlightFunc;
}

export const useMapFeatureState = (): MapFeatureStateResult => {
  const { map: mapInstance } = useMapContext();

  const highlightFeature = (feature: HighlightableFeature) => {
    if (!mapInstance) {
      return () => undefined;
    }

    mapInstance.setFeatureState(feature, { [FEATURESTATE_HIGHLIGHT]: true });

    return () => {
      mapInstance.setFeatureState(feature, {
        [FEATURESTATE_HIGHLIGHT]: false
      });
    };
  };

  return { highlightFeature };
};
