import {
  createStyles,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles
} from '@material-ui/core';

import ExpandMore from '@material-ui/icons/ExpandMore';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import React from 'react';

interface GroupListItemProps {
  groupName: string;
  showLayers: boolean;
  isExpanded: boolean;

  toggleShowLayers: () => void;
  toggleExpand: () => void;
}

const useStyles = makeStyles(() =>
  createStyles({
    settingsIcon: {
      transform: (props: { isExpanded: boolean }) =>
        props.isExpanded ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform .3s'
    }
  })
);

const GroupListItem: React.FC<GroupListItemProps> = ({
  groupName,
  showLayers,
  toggleShowLayers,
  isExpanded,
  toggleExpand
}) => {
  const classes = useStyles({ isExpanded });

  return (
    <ListItem button onClick={toggleShowLayers}>
      <ListItemIcon>
        {showLayers ? (
          <CheckBoxIcon color="primary" />
        ) : (
          <CheckBoxOutlineBlankIcon color="disabled" />
        )}
      </ListItemIcon>
      <ListItemText primary={groupName} />
      <ListItemSecondaryAction>
        <IconButton
          className={classes.settingsIcon}
          onClick={toggleExpand}
          edge="end"
          aria-label="expand-list"
        >
          <ExpandMore />
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default GroupListItem;
