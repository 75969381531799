import { GeocodeResult } from './interfaces';

interface GeocodeFeatureData {
  // eslint-disable-next-line camelcase
  place_name: string;
  center: [number, number];
}

const defaultOptions = {
  limit: 5,
  url: 'https://api.mapbox.com/geocoding/v5/mapbox.places'
};

export const mapboxGeocode = async (
  query: string,
  accessToken: string,
  options?: {
    limit?: number;
    /** [longitude, latitude] */
    proximity?: [number, number];
    url?: string;
  }
): Promise<GeocodeResult[]> => {
  const { limit, proximity, url } = { ...defaultOptions, ...options };

  const params = [
    `access_token=${accessToken}`,
    'country=US',
    `types=postcode,place,locality,neighborhood,address`,
    `limit=${limit}`
  ];
  if (proximity) {
    params.push(`proximity=${proximity.join(',')}`);
  }

  const requestUrl = `${url.replace(/\/$/, '')}/${query}.json?${params.join(
    '&'
  )}`;

  const response = await fetch(requestUrl);
  const data = await response.json();

  return data.features.map(
    ({ place_name: placeName, center }: GeocodeFeatureData) => {
      const splitPlace = placeName.split(',');
      return {
        primaryText: splitPlace[0],
        secondaryText: `${splitPlace[1]}, ${splitPlace[2]}`,
        geometry: { type: 'Point', coordinates: center }
      };
    }
  );
};
