import { Box, SvgIconProps, Tooltip } from '@material-ui/core';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import { CircleLayer } from 'mapbox-gl';
import React, { useEffect, useState } from 'react';

import { useGeolocation } from '../../hooks/useGeolocation';
import { useGeoJSONLayer } from '../../MapContext/useGeoJSONLayer';
import { useMapNavigation } from '../../MapContext';
import MapButton from '../MapButton';

import GeolocationPulse from './GeolocationPulse';

interface GeolocationButtonProps extends Pick<SvgIconProps, 'color'> {
  /** Zoom level to fly to after locating */
  zoom?: number;
}

const mapLayers = [
  {
    type: 'circle',
    paint: {
      'circle-color': '#2979ff',
      'circle-radius': 6,
      'circle-stroke-color': '#2979ff',
      'circle-stroke-width': 4.5,
      'circle-stroke-opacity': 0.3
    }
  } as CircleLayer
];

const GeolocationButton: React.FC<GeolocationButtonProps> = ({
  color = 'action',
  zoom = 14
}) => {
  const [locating, setLocating] = useState(false);
  // Use for actions needed the first time data is obtained after activating
  const [newLocating, setNewLocating] = useState(false);

  const { location, startWatching, stopWatching } = useGeolocation({});
  const { flyTo } = useMapNavigation();
  const { setData, clearData } = useGeoJSONLayer({
    layers: mapLayers
  });

  useEffect(() => {
    if (!location) return;

    const { longitude, latitude } = location;

    setData({
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Point',
        coordinates: [longitude, latitude]
      }
    });

    if (newLocating) {
      flyTo([longitude, latitude], zoom);
      setNewLocating(false);
    }
  }, [location, newLocating, setData, setNewLocating, flyTo, zoom]);

  const handleLocate = () => {
    setLocating(!locating);
    if (locating) {
      setNewLocating(false);
      stopWatching();
      clearData();
    } else {
      setNewLocating(true);
      startWatching();
    }
  };

  return (
    <Tooltip title="Your location">
      {/* Wrap in Material UI box to support Tooltip ref */}
      <Box>
        <MapButton onClick={handleLocate}>
          {locating === false ? (
            <LocationSearchingIcon color={color} style={{ fontSize: 16 }} />
          ) : (
            <GeolocationPulse />
          )}
        </MapButton>
      </Box>
    </Tooltip>
  );
};

export default GeolocationButton;
