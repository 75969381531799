import { Box, Grid, GridProps } from '@material-ui/core';
import React from 'react';

import { METADATA_URL } from '../../constants';
import { useMapStyle } from '../../MapContext/useMapStyle';

import StyleGalleryItem from './StyleGalleryItem';

export interface GalleryItem {
  title: string;
  previewImage: string;
  previewImage2x?: string;
  styleUrl: string;
}

/**
 * Accept Material UI style breakpoints (https://material-ui.com/components/grid/#grid-with-breakpoints)
 * to define column widths.
 * Defaults to `xs = true` - flex 1 0 0px columns for all screen sizes.
 */
type Breakpoints = Pick<GridProps, 'xs' | 'sm' | 'md' | 'lg' | 'xl'>;

export interface StyleGalleryProps extends Breakpoints {
  items: GalleryItem[];
  columns?: number;
}

/**
 * Grid of map styles to select and update a Map in the same context.
 *
 * Active style highlighting. To support highlighting the active style in the gallery,
 * map styles should include a root metadata property `lja:url` with the URL of the style JSON.
 * Any gallery items with a `styleUrl` containing the active style metadata URL
 * will be in the active state.
 */
const StyleGallery: React.FC<StyleGalleryProps> = ({
  items,
  xs = true,
  ...breakpoints
}) => {
  const { mapStyle, setMapStyleFromURL } = useMapStyle();

  const activeUrl = mapStyle?.metadata?.[METADATA_URL];

  return (
    <Box p={1}>
      <Grid container>
        {items.map(({ title, previewImage, previewImage2x, styleUrl }) => (
          <Grid item xs={xs} {...breakpoints} key={styleUrl}>
            <StyleGalleryItem
              title={title}
              previewImage={previewImage}
              previewImage2x={previewImage2x}
              isActive={!!(activeUrl && styleUrl.includes(activeUrl))}
              onClick={() => setMapStyleFromURL(styleUrl)}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default StyleGallery;
