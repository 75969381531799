import { useCallback } from 'react';

import { useMapContext } from './MapContext';

interface MapLayerVisibilityResult {
  showLayer: (id: string) => void;
  hideLayer: (id: string) => void;
  showLayers: (layerIds: string[]) => void;
  hideLayers: (layerIds: string[]) => void;
}

export const useMapLayerVisibility = (): MapLayerVisibilityResult => {
  const { map: mapInstance } = useMapContext();

  const showLayer = useCallback(
    (id: string) => {
      if (!mapInstance) {
        return;
      }
      mapInstance.setLayoutProperty(id, 'visibility', 'visible');
    },
    [mapInstance]
  );

  const hideLayer = useCallback(
    (id: string) => {
      if (!mapInstance) {
        return;
      }
      mapInstance.setLayoutProperty(id, 'visibility', 'none');
    },
    [mapInstance]
  );

  const showLayers = useCallback(
    (layerIds: string[]) => {
      layerIds.forEach((layerId) => showLayer(layerId));
    },
    [showLayer]
  );

  const hideLayers = useCallback(
    (layerIds: string[]) => {
      layerIds.forEach((layerId) => hideLayer(layerId));
    },
    [hideLayer]
  );

  return { showLayer, hideLayer, showLayers, hideLayers };
};
