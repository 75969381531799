import { Box, BoxProps, Typography } from '@material-ui/core';
import React from 'react';
import {
  acres,
  decimalDegrees,
  degMinSec,
  formatFeet,
  miles,
  sqFeet
} from './formatMeasurements';

type RootBoxProps = BoxProps;

export interface MeasureResultsProps extends RootBoxProps {
  lastPoint: {
    lng: number;
    lat: number;
  } | null;
  length: number | null;
  area: number | null;
}

const MeasureResults: React.FC<MeasureResultsProps> = ({
  lastPoint,
  length,
  area,
  ...rootBoxProps
}) => (
  <Box {...rootBoxProps}>
    {!lastPoint && (
      <Typography gutterBottom>
        Start creating a measurement by adding points to the map
      </Typography>
    )}
    {lastPoint && (
      <Box width={1} display="flex" flexDirection="row">
        <Box flex="1 0 auto" mr={3}>
          <Typography color="textSecondary" gutterBottom>
            Last Point
          </Typography>
        </Box>
        <Box flex="0 1 auto">
          <Typography align="right">{degMinSec(lastPoint)}</Typography>
          <Typography align="right">{decimalDegrees(lastPoint)}</Typography>
        </Box>
      </Box>
    )}
    {length && (
      <Box width={1} mt={lastPoint ? 2 : 0} display="flex" flexDirection="row">
        <Box flex="1 0 auto" mr={3}>
          <Typography color="textSecondary" gutterBottom>
            Path Distance
          </Typography>
        </Box>
        <Box flex="0 1 auto">
          <Typography align="right">{formatFeet(length)}</Typography>
          <Typography align="right">{miles(length)}</Typography>
        </Box>
      </Box>
    )}
    {area && (
      <Box
        width={1}
        mt={lastPoint || length ? 2 : 0}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Box flex="0 0 auto" mr={3}>
          <Typography color="textSecondary" gutterBottom>
            Area
          </Typography>
        </Box>
        <Box flex="0 1 auto">
          <Typography align="right">{sqFeet(area)}</Typography>
          <Typography align="right">{acres(area)}</Typography>
        </Box>
      </Box>
    )}
  </Box>
);

export default MeasureResults;
