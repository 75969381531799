import { ListItem, ListItemIcon } from '@material-ui/core';
// TODO: @material-ui/lab causing build error
// import { Skeleton } from '@material-ui/lab';
import React from 'react';

const LoadingListItem: React.FC = () => (
  <ListItem>
    <ListItemIcon>
      {/* <Skeleton variant="rect" width={18} height={18} /> */}
    </ListItemIcon>
    {/* <Skeleton variant="rect" width={210} height={18} /> */}
  </ListItem>
);

export default LoadingListItem;
