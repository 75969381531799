import * as React from 'react';
import { Box, Typography } from '@material-ui/core';

const StackedTitle: React.FC<{ title: string; subtitle: string }> = ({
  title,
  subtitle
}) => (
  <Box>
    <Typography variant="h3">{title}</Typography>
    <Typography style={{ opacity: 0.7 }} variant="subtitle2">
      {subtitle}
    </Typography>
  </Box>
);

export default StackedTitle;
