import { Button, makeStyles, Box, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';

export interface StyleGalleryItemProps {
  title: string;
  previewImage: string;
  previewImage2x?: string;
  isActive: boolean;
  onClick: () => void;
}

const useStyles = makeStyles((theme) => ({
  button: {
    minWidth: 'initial',
    width: '100%'
  },
  image: {
    height: theme.spacing(6),
    width: '100%',
    padding: 1,
    objectFit: 'none',
    border: 'solid 2px transparent',
    borderRadius: theme.spacing(1)
  },
  active: {
    borderColor: theme.palette.primary.main
  },
  title: {
    width: '100%',
    marginTop: theme.spacing(0.5),
    lineHeight: 1,
    transition: 'color 0.5s'
  }
}));

const StyleGalleryItem: React.FC<StyleGalleryItemProps> = ({
  title,
  previewImage,
  previewImage2x,
  isActive,
  onClick
}) => {
  const classes = useStyles();

  return (
    <Button className={classes.button} onClick={onClick}>
      <Box width={1}>
        <img
          src={previewImage}
          srcSet={
            previewImage2x !== undefined
              ? `${previewImage} 1x, ${previewImage2x} 2x`
              : undefined
          }
          alt=""
          className={clsx({
            [classes.image]: true,
            [classes.active]: isActive
          })}
        />
        <Typography
          className={classes.title}
          variant="caption"
          display="block"
          align="center"
          noWrap
          color={isActive ? 'primary' : 'textSecondary'}
        >
          {title}
        </Typography>
      </Box>
    </Button>
  );
};

export default StyleGalleryItem;
