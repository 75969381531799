import * as React from 'react';
import {
  Box,
  BoxProps,
  Divider,
  Drawer,
  makeStyles,
  Theme
} from '@material-ui/core';
import clsx from 'clsx';

const DRAWER_WIDTH = 320;

const useStyles = makeStyles((theme: Theme) => ({
  drawerPaper: {
    position: 'absolute',
    bottom: 0,
    height: 'auto',
    width: DRAWER_WIDTH,
    marginTop: theme.spacing(1) * -1,
    borderTopLeftRadius: theme.shape.borderRadius
  },
  drawerDockedRight: {
    borderLeft: 0
  },
  map: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: 0
  },
  mapShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: DRAWER_WIDTH
  },
  mapTopLeft: {
    width: 'auto',
    position: 'absolute',
    top: theme.spacing(1),
    left: theme.spacing(2),
    '& > *': {
      marginTop: theme.spacing(1)
    }
  },
  mapTopRight: {
    width: 'auto',
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(2),
    '& > *': {
      marginTop: theme.spacing(1)
    }
  }
}));

type RootProps = Pick<
  BoxProps,
  'height' | 'width' | 'flex' | 'margin' | 'className' | 'style'
>;

interface Layout1Props extends RootProps {
  header: React.ReactNode;
  map: React.ReactNode;
  mapOverlayTopLeft?: React.ReactNodeArray;
  mapOverlayTopRight?: React.ReactNodeArray;
  sidebar?: React.ReactElement<{ height: string }>;
}

const Layout1: React.FC<Layout1Props> = (props) => {
  const {
    header,
    map,
    mapOverlayTopLeft,
    mapOverlayTopRight,
    sidebar,
    ...rootProps
  } = props;

  const classes = useStyles();

  const sidebarOpen = !!sidebar;

  return (
    <Box display="flex" flexDirection="column" overflow="hidden" {...rootProps}>
      <Box flex="0 0 auto">{header}</Box>
      <Divider />
      <Box flex="1 1 0%" position="relative" display="flex" flexDirection="row">
        <Drawer
          classes={{
            paper: classes.drawerPaper,
            paperAnchorDockedRight: classes.drawerDockedRight
          }}
          PaperProps={{
            elevation: 3
          }}
          variant="persistent"
          anchor="right"
          open={sidebarOpen}
        >
          {sidebar}
        </Drawer>
        <Box
          className={clsx(classes.map, {
            [classes.mapShift]: sidebarOpen
          })}
          flex="1 1 0%"
          display="block"
          position="relative"
        >
          <Box height={1} width={1}>
            {map}
          </Box>
          {mapOverlayTopLeft && mapOverlayTopLeft.length > 0 && (
            <Box
              className={classes.mapTopLeft}
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
            >
              {mapOverlayTopLeft}
            </Box>
          )}
          {mapOverlayTopRight && mapOverlayTopRight.length > 0 && (
            <Box
              className={classes.mapTopRight}
              display="flex"
              flexDirection="column"
              alignItems="flex-end"
            >
              {mapOverlayTopRight}
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Layout1;
