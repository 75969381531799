export interface Coordinate {
  lng: number;
  lat: number;
}
export type CoordinateArray = Coordinate[];

export const buildPointFeatures = (
  coords: CoordinateArray
): GeoJSON.Feature<GeoJSON.MultiPoint> => {
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'MultiPoint',
      coordinates: coords.map((coord) => [coord.lng, coord.lat])
    }
  };
};

export const buildLineFeature = (
  coords: CoordinateArray
): GeoJSON.Feature<GeoJSON.LineString> => {
  return {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'LineString',
      coordinates: coords.map((coord) => [coord.lng, coord.lat])
    }
  };
};

export const buildProjectedLineFeature = (
  coords: CoordinateArray,
  current: Coordinate
): GeoJSON.Feature<GeoJSON.LineString> => {
  const startLocation = coords[coords.length - 1];
  return {
    type: 'Feature',
    properties: { projected: true },
    geometry: {
      type: 'LineString',
      coordinates: [
        [startLocation.lng, startLocation.lat],
        [current.lng, current.lat]
      ]
    }
  };
};

export const buildCurrentMouseFeature = (
  coord: Coordinate
): GeoJSON.Feature<GeoJSON.Point> => {
  return {
    type: 'Feature',
    properties: { projected: true },
    geometry: {
      type: 'Point',
      coordinates: [coord.lng, coord.lat]
    }
  };
};
