import {
  AnyLayer,
  CircleLayer,
  FillExtrusionLayer,
  FillLayer,
  GeoJSONSource,
  GeoJSONSourceOptions,
  HeatmapLayer,
  LineLayer,
  SymbolLayer
} from 'mapbox-gl';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { emptyGeoJSONFeature } from '../utils';

import { useMapContext } from './MapContext';

/**
 * Supported GeoJSON data formats
 * May be an inline GeoJSON FeatureCollection or Feature or a URL to a GeoJSON file
 */
type Data =
  | GeoJSON.Feature<GeoJSON.Geometry>
  | GeoJSON.FeatureCollection<GeoJSON.Geometry>
  | string;

/**
 * Mapbox GeoJSON source options.
 * https://docs.mapbox.com/mapbox-gl-js/style-spec/sources/#geojson.
 * `data` is required.
 */
interface SourceOptions extends GeoJSONSourceOptions {
  data: Data;
}

type SupportedLayers =
  | CircleLayer
  | FillExtrusionLayer
  | FillLayer
  | HeatmapLayer
  | LineLayer
  | SymbolLayer;

/**
 * Mapbox layer options
 * https://docs.mapbox.com/mapbox-gl-js/style-spec/layers/
 * Circle, FillExtrusion, Fill, Heatmap, Line, and Symbol layers are supported with GeoJSON.
 * `id`, `source`, and `sourceLayer` are managed internally and ignored if included.
 */
type LayerOptions = Omit<SupportedLayers, 'id' | 'source' | 'sourceLayer'> & {
  /** Optionally load an image to the map sprites for use in the style layer */
  image?: {
    /** id used to reference the image in the style (ie. layout['icon-image']) */
    id: string;
    /** URL source */
    source: string;
  };
};

interface GeoJSONLayerResult {
  setData: (data: Data) => void;
  clearData: () => void;
  sourceId: string;
}

interface GeoJSONLayerOptions {
  /**
   * Optional source configuration for source related properties and initial GeoJSON data set
   * Do not provide `data` if the layer should not be rendered immediately. Use `setData` later as needed.
   */
  source?: SourceOptions;
  layers: LayerOptions[];
}

export const useGeoJSONLayer = ({
  source = { data: emptyGeoJSONFeature },
  layers
}: GeoJSONLayerOptions): GeoJSONLayerResult => {
  const { map: mapInstance } = useMapContext();
  const [sourceData, setData] = useState<Data>(source.data);

  const idRef = useRef(uuid());

  // Prevent source and layer options from changing after instantiation
  const sourceOptions = useRef(source);
  const layersOptions = useRef(layers);

  /**
   * GeoJSON source layer in the map style.
   * Can be used to directly update on data changes.
   */
  const geoJSONSource = useRef<GeoJSONSource>();

  useEffect(() => {
    if (!mapInstance) return undefined;

    const id = idRef.current;
    const layers = layersOptions.current;

    /** Add GeoJSON source and layer to the map style */
    const addGeoJSON = () => {
      if (mapInstance.getSource(id)) {
        // GeoJSON has already been added to the current map style
        return;
      }

      mapInstance.addSource(id, {
        type: 'geojson',
        ...sourceOptions.current
      });

      geoJSONSource.current = mapInstance.getSource(id) as GeoJSONSource;

      layers.forEach((layer, index) => {
        if (layer.image) {
          // Load image before adding the layer
          const { id: imageId, source } = layer.image;
          mapInstance.loadImage(
            source,
            (error: Error, imageBitmap: ImageBitmap) => {
              if (error) throw error;
              imageId && mapInstance.addImage(imageId, imageBitmap);
              mapInstance.addLayer({
                source: id,
                id: `${id}-${index + 1}`,
                ...layer
              } as AnyLayer);
            }
          );
          return;
        }

        mapInstance.addLayer({
          source: id,
          id: `${id}-${index + 1}`,
          ...layer
        } as AnyLayer);
      });
    };

    addGeoJSON();

    mapInstance.on('style.load', addGeoJSON);

    return () => {
      mapInstance.off('style.load', addGeoJSON);
      layers.forEach((layer, index) => {
        mapInstance.removeLayer(`${id}-${index + 1}`);
        layer.image?.id && mapInstance.removeImage(layer.image?.id);
      });
      mapInstance.removeSource(id);
      geoJSONSource.current = undefined;
    };
  }, [mapInstance]);

  useEffect(() => {
    // Update `sourceOptions` data so it is available on style changes
    sourceOptions.current.data = sourceData;

    if (!geoJSONSource.current) return;
    geoJSONSource.current.setData(sourceData);
  }, [sourceData]);

  /** Clear all layer data */
  const clearData = useCallback(() => setData(emptyGeoJSONFeature), [setData]);

  return { setData, clearData, sourceId: idRef.current };
};
