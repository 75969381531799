import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText
} from '@material-ui/core';

import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import React from 'react';

interface SingularListItemProps {
  layerName: string;
  showLayer: boolean;

  toggleShowLayer: () => void;
  zoomIn?: () => void;
  zoomOut?: () => void;
}

const SingularListItem: React.FC<SingularListItemProps> = ({
  layerName,
  showLayer,
  toggleShowLayer,
  zoomIn,
  zoomOut
}) => {
  return (
    <ListItem button onClick={toggleShowLayer}>
      <ListItemIcon>
        {showLayer ? (
          <CheckBoxIcon color={zoomIn || zoomOut ? 'disabled' : 'primary'} />
        ) : (
          <CheckBoxOutlineBlankIcon color="disabled" />
        )}
      </ListItemIcon>
      <ListItemText primary={layerName} />
      {zoomIn && showLayer && (
        <ListItemSecondaryAction>
          <IconButton onClick={zoomIn} edge="end" aria-label="zoom-in-to-level">
            <ZoomInIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
      {zoomOut && showLayer && (
        <ListItemSecondaryAction>
          <IconButton
            onClick={zoomOut}
            edge="end"
            aria-label="zoom-out-to-level"
          >
            <ZoomOutIcon />
          </IconButton>
        </ListItemSecondaryAction>
      )}
    </ListItem>
  );
};

export default SingularListItem;
