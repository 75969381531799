import {
  Box,
  BoxProps,
  createStyles,
  Link,
  makeStyles,
  Theme,
  Typography
} from '@material-ui/core';
import OpenIcon from '@material-ui/icons/LaunchOutlined';
import React from 'react';

import { ImageAttribute } from './interfaces';

type RootBoxProps = BoxProps;

export type ImageAttributeItemProps = RootBoxProps &
  Omit<ImageAttribute, 'kind'>;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      display: 'block',
      position: 'relative',
      overflow: 'hidden',
      borderRadius: theme.shape.borderRadius,
      '& .hover-overlay': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        right: 0,
        left: 0,
        background: theme.palette.action.active,
        color: theme.palette.grey['400'],
        opacity: 0,
        transition: `opacity ${theme.transitions.duration.standard / 1000}s`
      },
      '&:hover .hover-overlay, .Mui-focusVisible .hover-overlay': {
        opacity: 1
      }
    },
    image: {
      display: 'block',
      height: 'auto',
      width: '100%'
    },
    openIcon: {
      marginRight: theme.spacing(1)
    }
  })
);

const ImageAttributeItem: React.FC<ImageAttributeItemProps> = ({
  label,
  url,
  ...rootBoxProps
}) => {
  const classes = useStyles();

  return (
    <Box mt={1} {...rootBoxProps}>
      <Box mb={1}>
        <Typography color="textSecondary">{label}</Typography>
      </Box>
      <Link className={classes.link} href={url} target="_blank" rel="noopener">
        <img className={classes.image} alt={label} src={url} />
        <Box
          className="hover-overlay"
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
          p={2}
        >
          <OpenIcon className={classes.openIcon} color="inherit" />
          <Typography variant="subtitle1" color="inherit">
            View Full Size Image
          </Typography>
        </Box>
      </Link>
    </Box>
  );
};

export default ImageAttributeItem;
