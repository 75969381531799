import { SvgIconProps } from '@material-ui/core';
import ZoomInIcon from '@material-ui/icons/Add';
import * as React from 'react';

import { useMapNavigation } from '../../MapContext';

import MapButton from '../MapButton';

type ZoomInButtonProps = Pick<SvgIconProps, 'color'>;

const ZoomInButton: React.FC<ZoomInButtonProps> = ({ color = 'action' }) => {
  const { zoomIn } = useMapNavigation();
  return (
    <MapButton onClick={zoomIn}>
      <ZoomInIcon color={color} style={{ fontSize: 20 }} />
    </MapButton>
  );
};

export default ZoomInButton;
