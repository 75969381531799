import { Map } from 'mapbox-gl';
import React, { useContext, useState } from 'react';

interface MapContextProps {
  map?: Map;
}

const MapContext = React.createContext<MapContextProps | undefined>(undefined);

type UpdateMapContextProps = React.Dispatch<
  React.SetStateAction<Map | undefined>
>;

const UpdateMapContext = React.createContext<UpdateMapContextProps | undefined>(
  undefined
);

export const useMapContext = (): MapContextProps => {
  const context = useContext(MapContext);
  if (context === undefined) {
    throw new Error('useMapContext should be used in a MapProvider');
  }
  return context as MapContextProps;
};

export const useSetMapContext = (): UpdateMapContextProps => {
  const context = useContext(UpdateMapContext);
  if (context === undefined) {
    throw new Error('useSetMapContext should be used in a MapProvider');
  }
  return context as UpdateMapContextProps;
};

export const MapProvider: React.FC = ({ children }) => {
  const [mapState, setMapState] = useState<Map | undefined>();

  return (
    <MapContext.Provider value={{ map: mapState }}>
      <UpdateMapContext.Provider value={setMapState}>
        {children}
      </UpdateMapContext.Provider>
    </MapContext.Provider>
  );
};
