import { LngLat, LngLatBounds, MapboxEvent } from 'mapbox-gl';
import { useEffect, useState } from 'react';

import { useMapContext } from './MapContext';

interface Viewport {
  center: LngLat;
  zoom: number;
  bearing: number;
  pitch: number;
  bounds: LngLatBounds;
}

type MapViewportResult = Partial<Viewport>;

export const useMapViewport = (): MapViewportResult => {
  const { map: mapInstance } = useMapContext();

  const [viewport, setViewport] = useState<MapViewportResult>({});

  /**
   * Sets viewport properties on initial load and after a map has completed a navigation
   * (pan, zoom, flyTo, fitBounds, etc..)
   */
  useEffect(() => {
    if (!mapInstance) {
      return undefined;
    }

    const initialCenter = mapInstance.getCenter();
    const initialZoom = mapInstance.getZoom();
    const initialBearing = mapInstance.getBearing();
    const initialPitch = mapInstance.getPitch();
    const initialBounds = mapInstance.getBounds();

    setViewport({
      center: initialCenter,
      zoom: initialZoom,
      bearing: initialBearing,
      pitch: initialPitch,
      bounds: initialBounds
    });

    const onMoveEnd = ({ target: map }: MapboxEvent<unknown>) => {
      const center = map.getCenter();
      const zoom = map.getZoom();
      const bearing = map.getBearing();
      const pitch = map.getPitch();
      const bounds = map.getBounds();
      setViewport({ center, zoom, bearing, pitch, bounds });
    };
    mapInstance.on('moveend', onMoveEnd);

    return () => {
      mapInstance.off('moveend', onMoveEnd);
    };
  }, [mapInstance]);

  return viewport;
};
