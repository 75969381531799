import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import ShowIcon from '@material-ui/icons/VisibilityOutlined';
import React from 'react';

interface ShowAllListItemProps {
  onClick: () => void;
}

const ShowAllListItem: React.FC<ShowAllListItemProps> = ({ onClick }) => (
  <ListItem button onClick={onClick} dense>
    <ListItemIcon>
      <ShowIcon color="primary" />
    </ListItemIcon>
    <ListItemText primary="Show all layers" />
  </ListItem>
);

export default ShowAllListItem;
